import {
  EuiButton,
  EuiFieldPassword,
  EuiFieldText,
  EuiForm,
  EuiFormRow,
  EuiSpacer,
  EuiText,
} from "@elastic/eui";
import React from "react";
import { applicationClass, formClass, logosClass } from "./styles";

// import zicta_logo from "./assets/zicta-logo3.png";
import undp_logo from "./assets/undp-logo3.png";
import ntbc_logo from "./assets/ntbc-logo3.png";
import nii_logo from "./assets/nii-logo.png";
import { useHistory } from "react-router-dom";

export const AdminLogin: React.FC = () => {
  const history = useHistory();

  const login = () => {
    history.push("/admin/submissions");
  };

  return (
    <div className={applicationClass}>
      <EuiText style={{ maxWidth: 500, textAlign: "center", marginTop: 32 }}>
        <h2>National Innovation Initiative </h2>
        <div className={logosClass}>
          <img
            src={nii_logo}
            alt="NII Logo"
            style={{ width: "auto", height: 64, marginRight: 4 }}
          />
          <img
            src={ntbc_logo}
            alt="NTBC Logo"
            style={{ width: "auto", height: 64, marginRight: 4 }}
          />
          <img
            src={undp_logo}
            alt="UNDP Logo"
            style={{ width: "auto", height: 64, marginRight: 4 }}
          />
        </div>
        <h3>Administrator Portal</h3>
        <p>Login to view submitted Applications.</p>
      </EuiText>
      <EuiForm component="form" className={formClass}>
        <EuiFormRow label="Email" helpText="Enter your email address" fullWidth>
          <EuiFieldText name="email" fullWidth />
        </EuiFormRow>
        <EuiFormRow label="Password" helpText="Enter your password" fullWidth>
          <EuiFieldPassword name="password" fullWidth />
        </EuiFormRow>

        <EuiSpacer />

        <EuiButton onClick={() => login()} fill fullWidth>
          Login
        </EuiButton>
      </EuiForm>
    </div>
  );
};
