import React, { Fragment, useEffect, useState } from "react";
import {
  EuiButton,
  EuiFieldText,
  EuiForm,
  EuiFormRow,
  EuiFilePicker,
  EuiSpacer,
  EuiSuperSelect,
  EuiDatePicker,
  EuiTextArea,
  EuiText,
  EuiEmptyPrompt,
  htmlIdGenerator,
  EuiRadioGroup,
  EuiComboBox,
} from "@elastic/eui";

// import zicta_logo from "./assets/zicta-logo3.png";
import nii_logo from "./assets/nii-logo.png";
// import undp_logo from "./assets/undp-logo3.png";
import ntbc_logo from "./assets/logo-cropped.png";
import { Route, Switch, useHistory } from "react-router-dom";
import { db, storage } from "./firebase/firebase";
import { v4 } from "uuid";
import { eduOptions, genderOptions, provinceOptions } from "./Options";
import { applicationClass, formClass, logosClass, titleClass } from "./styles";
import { useForm } from "react-hook-form";
import { Moment } from "moment";
import { AdminLogin } from "./AdminLogin";
import { ApplicationSubmissions } from "./ApplicationSubmissions";
import { NIIHome } from "./NiiHome";

import axios from "axios";

const idPrefix = htmlIdGenerator()();

export const ApplicationForm: React.FC = () => {
  const history = useHistory();
  const [loading, setLoading] = useState<boolean>(false);
  const [eduLevel, setEduLevel] = useState<string>("");
  const [gender, setGender] = useState<string>("");
  const [eduErrors, setEduError] = useState<boolean>(false);
  const [province, setProvince] = useState<string>("");
  const [provinceErrors, setProvinceError] = useState(false);
  const [dateOfBirth, setdateOfBirth] = useState<Moment>();
  const [dobErrors, setDobErrors] = useState<boolean>(false);
  const [sdgErrors, setSDGErrors] = useState<boolean>(false);
  const [genderErrors, setGenderErrors] = useState<boolean>(false);

  const sdgOptions = [
    {
      label: "SDG 1. No Poverty",
    },
    {
      label: "SDG 2. Zero Hunger",
    },
    {
      label: "SDG 3. Good Health and Well-Being",
    },
    {
      label: "SDG 4. Quality Education",
    },
    {
      label: "SDG 5. Gender Equality",
    },
    {
      label: "SDG 6. Clean Water and Sanitation",
    },
    {
      label: "SDG 7. Affordable and Clean Energy",
    },
    {
      label: "SDG 8. Decent Work and Economic Growth",
    },
    {
      label: "SDG 9. Industry, Innovation, and Infrastructure",
    },
    {
      label: "SDG 10. Reduced Inequalities",
    },
    {
      label: "SDG 11. Sustainable Cities and Communities",
    },
    {
      label: "SDG 12. Responsible Consumption and Production",
    },
    {
      label: "SDG 13. Climate Action",
    },
    {
      label: "SDG 14. Life Below Water",
    },
    {
      label: "SDG 15. Life on Land",
    },
    {
      label: "SDG 16. Peace, Justice, and Strong Institutions",
    },
  ];

  const radios = [
    {
      id: `${idPrefix}0`,
      label: "Yes",
    },
    {
      id: `${idPrefix}1`,
      label: "No",
    },
  ];

  const permissions = [
    {
      id: `${idPrefix}0`,
      label: "Yes",
    },
    {
      id: `${idPrefix}1`,
      label: "No",
    },
  ];

  const [radioIdSelected, setRadioIdSelected] = useState(`${idPrefix}0`);
  const [radioIdSelectedPerm, setRadioIdSelectedPerm] = useState(
    `${idPrefix}0`
  );
  const [options] = useState(sdgOptions);
  const [selectedSDGOptions, setSelectedSDG] = useState([]);

  const onSDGChange = (selectedSDGOptions: any) => {
    setSelectedSDG(selectedSDGOptions);
  };
  const onRadioChange = (optionId: any) => {
    setRadioIdSelected(optionId);
  };

  const permissionChange = (optionId: any) => {
    setRadioIdSelectedPerm(optionId);
  };

  const [files, setFiles] = useState<any>(null);
  const onChange = (fs: any) => {
    setFiles(fs);
  };

  const { register, handleSubmit, errors } = useForm();

  const onFormSubmit = async (data: any) => {
    console.log(files);

    if (!gender) {
      setGenderErrors(true);
      return;
    }

    if (!province) {
      setProvinceError(true);
      return;
    }

    if (!eduLevel) {
      setEduError(true);
      return;
    }

    if (selectedSDGOptions.length < 1) {
      setSDGErrors(true);
      return;
    }

    if (!dateOfBirth) {
      setDobErrors(true);
      return;
    } else {
      setLoading(true);
      const id = v4();

      if (files) {
        await storage()
          .ref(`/nii-applications-2022/${id}/${files[0].name}`)
          .put(files[0])
          .then((x) =>
            x.ref.getDownloadURL().then(async (url) => {
              await db()
                .collection("nii-applications-2024")
                .doc(id)
                .set({
                  id,
                  ...data,
                  fileUrl: url,
                  sdgs: selectedSDGOptions,
                  province,
                  eduLevel,
                  gender,
                  hasPrototype: radios.map((x) => x.id === radioIdSelected)[0],
                  isPermissionGiven: permissions.map(
                    (x) => x.id === radioIdSelected
                  )[0],
                  dob: db.Timestamp.fromDate(dateOfBirth.toDate()),
                  timestamp: db.Timestamp.now(),
                });
            })
          );
      } else {
        await db()
          .collection("nii-applications-2024")
          .doc(id)
          .set({
            id,
            ...data,
            sdgs: selectedSDGOptions,
            province,
            eduLevel,
            gender,
            hasPrototype: radios.map((x) => x.id === radioIdSelected)[0],
            isPermissionGiven: permissions.map(
              (x) => x.id === radioIdSelected
            )[0],
            dob: db.Timestamp.fromDate(dateOfBirth.toDate()),
            timestamp: db.Timestamp.now(),
          });
      }
    }

    localStorage.setItem("recipient", data.email);
    localStorage.setItem("recipientsName", data.innovatorName);

    history.push("/submitted");
  };

  const handleChange = (date: any) => {
    setdateOfBirth(date);
  };

  return (
    <div className={applicationClass}>
      <div className={titleClass}>
        <div className={logosClass}>
          <img
            src={nii_logo}
            alt="NII Logo"
            style={{ width: "auto", height: 64, marginRight: 4 }}
          />
        </div>
        <EuiText style={{ maxWidth: 500 }}>
          <h2>National Innovation Initiative </h2>
          <p>
            The call for applications is open up to 20th September, 2024 at
            23:59CAT. For more Information call, text, or Whatsapp this number
            +(260) 954 235 099
          </p>
        </EuiText>
        <EuiSpacer size="s" />
        {/* <div className={logosClass}>
          <img
            src={ntbc_logo}
            alt="NTBC Logo"
            style={{ width: "auto", height: 64, marginRight: 4 }}
          />
          <img
            src={undp_logo}
            alt="UNDP Logo"
            style={{ width: "auto", height: 64, marginRight: 4 }}
          />
        </div> */}
      </div>
      <EuiForm
        component="form"
        className={formClass}
        onSubmit={handleSubmit(onFormSubmit)}
        isInvalid={dobErrors}
      >
        <EuiFormRow
          fullWidth
          label="Name of Innovator"
          helpText="If you are in a group, add the name of the team leader."
        >
          <EuiFieldText
            name="innovatorName"
            inputRef={register({ required: true })}
            isInvalid={errors.innovatorName && true}
            fullWidth
          />
        </EuiFormRow>
        <EuiFormRow
          label="Gender of Innovator"
          isInvalid={genderErrors}
          fullWidth
        >
          <EuiSuperSelect
            options={genderOptions}
            valueOfSelected={gender}
            fullWidth
            isInvalid={genderErrors}
            onChange={(value: any) => setGender(value)}
          />
        </EuiFormRow>
        <EuiFormRow
          fullWidth
          label="Are you living with a disability?"
          helpText="If yes, please specify the type of disability. Otherwise, leave blank."
        >
          <EuiFieldText
            name="disability"
            inputRef={register({ required: false })}
            isInvalid={errors.disability && true}
            fullWidth
          />
        </EuiFormRow>
        <EuiFormRow
          label="Please select your province."
          isInvalid={provinceErrors}
          helpText="Note that the initiative is being implemented in these provinces only: Lusaka, Southern, North Western, Copperbelt, Western and Luapula."
          fullWidth
        >
          <EuiSuperSelect
            options={provinceOptions}
            valueOfSelected={province}
            isInvalid={provinceErrors}
            fullWidth
            onChange={(value: any) => setProvince(value)}
          />
        </EuiFormRow>
        <EuiFormRow label="Date of Birth" isInvalid={false} fullWidth>
          <EuiDatePicker
            isInvalid={dobErrors}
            selected={dateOfBirth}
            placeholder="Enter date of birth"
            onChange={handleChange}
            fullWidth
          />
        </EuiFormRow>
        <EuiFormRow
          label="NRC Number"
          helpText="Enter your NRC, i.e., 123456/78/9."
          fullWidth
        >
          <EuiFieldText
            name="nrcNumber"
            inputRef={register({ required: true })}
            fullWidth
          />
        </EuiFormRow>
        <EuiFormRow
          label="Contact Number"
          helpText="Your primary contact number."
          fullWidth
        >
          <EuiFieldText
            name="contact"
            inputRef={register({ required: true })}
            fullWidth
          />
        </EuiFormRow>

        <EuiFormRow label="Alternative Contact Number" fullWidth>
          <EuiFieldText
            name="altContact"
            inputRef={register({ required: true })}
            fullWidth
          />
        </EuiFormRow>

        <EuiFormRow label="Email address" fullWidth>
          <EuiFieldText
            name="email"
            inputRef={register({ required: true })}
            fullWidth
          />
        </EuiFormRow>

        <EuiFormRow
          label="Highest Level of Education"
          isInvalid={eduErrors}
          fullWidth
        >
          <EuiSuperSelect
            options={eduOptions}
            valueOfSelected={eduLevel}
            fullWidth
            isInvalid={eduErrors}
            onChange={(value: any) => setEduLevel(value)}
          />
        </EuiFormRow>

        <EuiFormRow
          label="Title of Innovation"
          helpText="Maximum of 50 words."
          fullWidth
        >
          <EuiFieldText
            name="title"
            inputRef={register({ required: true })}
            fullWidth
          />
        </EuiFormRow>

        <EuiFormRow
          label="Brief Innovation Description"
          helpText="Maximum of 200 words."
          fullWidth
        >
          <EuiTextArea
            name="description"
            placeholder="Give a brief description on what your innovation is and what problem is being solved or process being improved"
            inputRef={register({ required: true })}
            fullWidth
          />
        </EuiFormRow>

        <EuiFormRow
          label="Commercial Viability of the Innovation"
          helpText="Maximum of 200 words."
          fullWidth
        >
          <EuiTextArea
            name="viability"
            placeholder="Explain how your innovation will generate income for you or others or how it can assist in improving operations of existing businesses, specify your target market/potential offtakers"
            inputRef={register({ required: true })}
            fullWidth
          />
        </EuiFormRow>

        <EuiFormRow
          label="Technical Feasibility of the Innovation"
          helpText="Maximum of 200 words."
          fullWidth
        >
          <EuiTextArea
            name="feasibility"
            placeholder="Explain how your innovation is designed and what prototype exists if any"
            inputRef={register({ required: true })}
            fullWidth
          />
        </EuiFormRow>

        <EuiFormRow
          label="Anticipated Social Impact"
          helpText="Maximum of 200 words."
          fullWidth
        >
          <EuiTextArea
            name="socialImpact"
            placeholder="Explain how your innovation can improve the lives of others"
            inputRef={register({ required: true })}
            fullWidth
          />
        </EuiFormRow>

        <EuiFormRow
          label="Sustainable Development Goals Impacted"
          isInvalid={sdgErrors}
          fullWidth
        >
          <EuiComboBox
            placeholder="Select from the list"
            options={options}
            selectedOptions={selectedSDGOptions}
            onChange={onSDGChange}
            isInvalid={sdgErrors}
            isClearable={true}
            data-test-subj="demoComboBox"
          />
        </EuiFormRow>

        <EuiFormRow>
          <EuiRadioGroup
            options={radios}
            idSelected={radioIdSelected}
            onChange={(id) => onRadioChange(id)}
            name="hasPrototype"
            legend={{
              children: (
                <span>Do you have a prototype for your innovation?</span>
              ),
            }}
          />
        </EuiFormRow>

        <EuiFormRow>
          <EuiRadioGroup
            options={permissions}
            idSelected={radioIdSelectedPerm}
            onChange={(id) => permissionChange(id)}
            name="permissionGiven"
            legend={{
              children: (
                <span>
                  Do you grant us permission to share your innovation with our
                  partners for similar or future engagements?
                </span>
              ),
            }}
          />
        </EuiFormRow>

        <EuiFormRow
          label="Supporting Image"
          helpText="Add an image to support your innovation"
          fullWidth
        >
          <EuiFilePicker
            fullWidth
            accept="image/x-png,image/gif,image/jpeg"
            onChange={(files) => {
              onChange(files);
            }}
          />
        </EuiFormRow>

        <EuiSpacer />

        <EuiSpacer />

        <EuiButton fill type="submit" isLoading={loading}>
          Submit Innovation
        </EuiButton>
      </EuiForm>
      <div className={logosClass}>
        <img
          src={ntbc_logo}
          alt="NTBC Logo"
          style={{ width: "auto", height: 124, marginRight: 4 }}
        />
        {/* <img
          src={undp_logo}
          alt="UNDP Logo"
          style={{ width: "auto", height: 64, marginRight: 4 }}
        /> */}
      </div>
    </div>
  );
};

export const ApplicationComplete: React.FC = () => {
  const payload = {
    recipient: localStorage.getItem("recipient"),
    greeting: "0762432603",
    recipientsName: localStorage.getItem("recipientsName"),
    subject: "something",
    message: "something",
  };
  useEffect(() => {
    axios.post("https://ntbc-mailer.herokuapp.com/send-email", payload);
  }, [payload]);
  return (
    <div>
      <EuiEmptyPrompt
        iconType="editorStrike"
        title={<h2>Your Application has be Submitted</h2>}
        body={
          <Fragment>
            <p>Thank you for submitting your application.</p>
            <p>
              For further queries, call, text, or Whatsapp this number
              +260977622191
            </p>
          </Fragment>
        }
        actions={
          <EuiButton
            color="primary"
            fill
            onClick={() => (window.location.href = "https://ntbc.co.zm")}
          >
            Continue
          </EuiButton>
        }
      />
    </div>
  );
};

function App() {
  useEffect(() => {
    localStorage.clear();
  }, []);
  return (
    <Switch>
      <Route path="/" component={NIIHome} exact />
      <Route path="/apply" component={ApplicationForm} exact />
      <Route path="/submitted" component={ApplicationComplete} />
      <Route path="/admin/login" component={AdminLogin} />
      <Route path="/admin/submissions" component={ApplicationSubmissions} />
      <Route />
    </Switch>
  );
}

export default App;
