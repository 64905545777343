import { EuiButton, EuiSpacer, EuiText } from "@elastic/eui";
import React from "react";
import { useHistory } from "react-router-dom";
import { logosClass } from "./styles";
// import zicta_logo from "./assets/zicta-logo3.png";
// import undp_logo from "./assets/undp-logo3.png";
import ntbc_logo from "./assets/logo-cropped.png";
import nii_logo from "./assets/nii-logo.png";

export const NIIHome: React.FC = () => {
  const history = useHistory();
  return (
    <div style={{ maxWidth: 800, margin: "0 auto" }}>
      <div>
        <EuiSpacer size="l" />
        <div className={logosClass}>
          <img
            src={nii_logo}
            alt="NII Logo"
            style={{ width: "auto", height: 64, marginRight: 4 }}
          />
        </div>
        <EuiSpacer size="l" />
        <EuiText textAlign="center">
          <h1>National Innovation Initiative</h1>
        </EuiText>
        {/* <div className={logosClass}>
          <img
            src={ntbc_logo}
            alt="NTBC Logo"
            style={{ width: "auto", height: 64, marginRight: 4 }}
          />
          <img
            src={undp_logo}
            alt="UNDP Logo"
            style={{ width: "auto", height: 64, marginRight: 4 }}
          />
        </div> */}
        {/* <EuiSpacer size="l" />
        <EuiText textAlign="center">
          <h1>National Innovation Initiative</h1>
        </EuiText> */}
        {/* <EuiImage
                    size="fullWidth"
                    hasShadow
                    alt="NTBC & UNDP Acceleratory Lab"
                    url="https://res.cloudinary.com/ntbc-conduit/image/upload/v1602492395/thumbnail_UNDP_NTBC_ZICTA_-_Innovation_Scouting_03_bugegg.jpg"
                /> */}
        <EuiText>
          {/* <h1>National Innovation Initiative</h1> */}
          {/* <EuiSpacer size="l" />
          <EuiText textAlign="center">
            <h1>APPLICATIONS CLOSED</h1>
          </EuiText> */}
          <div style={{ textAlign: "center" }}>
            <EuiButton
              onClick={() => history.push("/apply")}
              fill
              style={{ marginBottom: 8 }}
            >
              APPLY ONLINE
            </EuiButton>
            <EuiButton
              style={{ marginLeft: 8, marginBottom: 8 }}
              href={"./nii-application-form-2024.docx"}
            >
              DOWNLOAD APPLICATION FORM
            </EuiButton>
          </div>
          <div style={{ margin: 24 }}>
            <h1>Introduction</h1>
            <p>
              Since the year 2020, the National Technology Business Centre
              (NTBC) with support from collaborating partners has been
              implementing the National Innovation Initiative (NII). The NII is
              aimed at supporting innovative solutions that address challenges
              in the communities.
            </p>
            <h1>Rationale</h1>
            <p>
              The National Innovation Initiative (NII) is being implemented in
              response to the National Science, Technology, and Innovation
              Policy of 2020 and in line with the 8th National Development Plan
              (8NDP) 4 priority areas which are (mining and mineral processing,
              agriculture and Agro processing; and energy).
            </p>
            <h1>Objectives of the National Innovation Initiative</h1>
            <p>
              The overall objective of the NII is to scout for innovative
              solutions for identified challenges affecting communities
              throughout the country. Besides being recognized, the selected
              innovative solutions will receive support for further
              commercialisation as per defined government innovation journey.
            </p>
            <h2>Specific Objectives</h2>
            <ul>
              <li>
                The overall objective of the NII is to scout for innovative
                solutions for identified challenges affecting communities
                throughout the country. Besides being recognized, the selected
                innovative solutions will receive support for further
                commercialisation as per defined government innovation journey.
              </li>
              <li>
                Recognize and support promising youth and women-led innovations
                and start-ups based on their innovativeness and potential for
                commercialisation.
              </li>
              <li>
                Promote technical assessment/experimentation in the development
                process of innovative ideas/solutions to generate evidence that
                can be used to support funding opportunities or scaling up.
              </li>
              <li>
                Provide innovators with technical and business development
                support with the view of enhancing their abilities and
                commercialisation of their innovations.
              </li>
              <li>
                Identify and support local home-grown solutions from innovations
                that exist within communities.
              </li>
              <li>
                Provide the NTBC database for innovations to be showcased for
                matchmaking and partnerships with potential off takers.
              </li>
              <li>
                Contribute to the acceleration of the country's trajectory
                towards 2030 on selected SDG indicators.
              </li>
            </ul>
            <h1>Implementation</h1>
            <p>
              This year's NII is being implemented under a "challenge approach"
              in which applicants are required to respond to the call for
              innovative solutions against the identified challenges.
            </p>
            <h2>Thematic Areas</h2>
            <p>
              To identify the challenges, NTBC held consultative meetings in the
              10 Provinces with stakeholders from the cooperatives, Business
              Associations, Chambers of Commerce, academic institutions and
              Provincial Administration offices to formulate challenges
              affecting their communities based on four thematic areas in the
              8th National Development Plan. It is these challenges that
              applicants will be responding to in the call for applications.
            </p>
            <p>
              This call invites innovators & start-ups
              Entrepreneurs/individual/groups with working prototypes to submit
              proposal for innovative solutions to solve identified challenges
              in the following areas:
            </p>
            <ul>
              <li>
                Agriculture (Water harvesting & Irrigation and Climate-Smart
                Agriculture technologies/innovations to mitigate drought
                conditions)
              </li>
              <li>
                Manufacturing: (Renewable energy and energy efficient
                technologies/innovations to mitigate the current power deficit),
                and (Agro-processing - value addition technologies/innovations
                to crops (cassava) for enhanced nutritional requirements)
              </li>
              <li>Adopting and adapting of emerging technologies in mining.</li>
              <li>
                Tourism (Geolocation-based Marketing and Environmental
                monitoring systems/Technologies /innovations to boost the
                Country’s tourism sector).
              </li>
            </ul>
            <h1>Target Group</h1>
            <p>
              The focus of the call is to support new or improved
              products/services/process that can address the identified
              challenges. Therefore, the call will be open to any individual,
              business, or consortiums (referred hereby as ‘Applicants’). The
              applicant should have a developed prototype/product/solution
              aligned to address the identified challenges.
            </p>
            <h1>Selection Process</h1>
            <p>
              Once applications have been received, a pre-screening will be
              conducted to ascertain compliance to the Challenge call criteria.
              Phase two will involve, a panel of judges selecting the best
              innovative solutions. Selected innovative solutions will receive
              grant funding.
            </p>
            <h1>Eligibility</h1>
            <p>
              The NII call is open to all Zambian citizens, institutions/groups.
              The general eligibility is that the qualifying solution should be
              one that demonstrates evidence of innovativeness and
              competitiveness. The eligibility criteria for projects will be
              prioritized to products/ processes/ services that:
            </p>
            <ul>
              <li>Have potential for employment and wealth creation</li>
              <li>Are in value addition to available local raw materials</li>
              <li>Have potential for export </li>
              <li>Are a direct intervention to an identified challenge</li>
              <li>Are in value addition to available local raw materials</li>
              <li>Demonstrate some commercial value </li>
            </ul>
            <h1>Type of Support</h1>
            <p>
              The awarded innovations will receive grant funding to develop,
              refine, and pilot the innovations in collaboration with the
              challenge owners, and the relevant support institutions, i.e.,
              research and development institutions, financiers, and regulatory
              agencies.
            </p>
            <h1>Terms and Conditions</h1>
            <p>
              These terms and conditions apply to the National Innovation Awards
              (NII). The applicants agree to these terms and conditions when
              they apply and if not, they are advised not to apply. These terms
              and conditions may be amended or varied at any time without prior
              notice. While the amendments will be publicized, it is the
              responsibility of the applicants to familiarize themselves with
              the changes
            </p>
            <ul>
              <li>
                The applicants must be Zambian and should attach a copy of the
                National Registration Card to the application
              </li>
              <li>
                Applications can be submitted as individuals or by a group (all
                group members are required to provide a copy of their National
                Registration Cards) The applications shall be submitted online
              </li>
              <li>
                Successful applicants shall be required to sign a contract that
                shall oblige them to allow NTBC to use their pictures/videos,
                share information of their innovation with third party for
                purposes of publicity, and to develop their innovation for
                possible offtake
              </li>
              <li>
                Successful applicants shall be willing to have their innovations
                availed for testing and willingness/availability to work closely
                with the identified municipalities
              </li>
              <li>
                NTBC may refuse to support a project in an event of
                non-entitlement under the set terms and conditions or on account
                of the applicant’s breach of the terms and conditions, criminal
                activity, fraud, default, and or dishonesty
              </li>
              <li>
                NTBC reserves the right, at its sole discretion, to disqualify
                any individual on account of providing false information or
                violating the terms and conditions
              </li>
              <li>
                NTBC may use the information provided by the applicant and their
                comments for future publicity in any media without notice or any
                additional compensation
              </li>
              <li>
                NTBC does not accept responsibility for any business failure,
                loss, malfunction, damage, disappointment or fault that may be
                incurred by the applicant or the proposed product howsoever
                arising from participating in this call whether due to error,
                omission, or any other cause by the NTBC, its employees air
                others acting within the law
              </li>
              <li>
                NTBC reserves the right to make the final selection decision
              </li>
              <li>
                All applications should be received by 23:59 hours on 20th
                September 2024. All applications received after this date will
                not be considered for this call.
              </li>
            </ul>
            {/* <ul>
              <li>
                Protect knowledge production by strengthening Intellectual
                Property Rights and regulatory regimes at all levels
              </li>
              <li>
                Promote economic competitiveness through fostering innovation,
                value addition, industrial development, and entrepreneurship
              </li>
              <li>
                Enhance effectiveness of innovation in addressing and
                implementing priority areas, and
              </li>
              <li>
                Improve technical competencies and institutional capacity for
                innovation development
              </li>
            </ul> */}
            {/* <p>
              Since inception, the NII has successfully contributed to several
              thematic areas as evidenced by the innovations that have been
              awarded. These innovations have been drawn from the following
              thematic areas:
            </p>
            <ul>
              <li>Enhancement of local technologies and innovations</li>
              <li>Wealth creation through innovation</li>
              <li>Food security</li>
              <li>Information Communication Technology</li>
              <li>Disease prevention and control</li>
              <li>Indigenous knowledge utilisation</li>
              <li>Effects of climate change</li>
            </ul>
            <h1>Objectives of The National Innovation Initiative (NII)</h1>
            <p>
              The overall objective of the NII is to scout for innovative
              solutions for identified challenges affecting communities
              throughout the country. Besides being recognized, the selected
              innovative solutions will receive support for further
              commercialisation as per defined government innovation journey.
            </p>
            <h2>Specific Objectives</h2>
            <ul>
              <li>
                Identification and mapping of innovations especially among the
                youths, women, differently abled and other marginalised groups
                across the country in selected thematic areas for
                experimentation, commercialisation and scale up.
              </li>
              <li>
                Recognize and support promising youth and women-led innovations
                and start-ups based on their innovativeness and potential for
                commercialisation
              </li>
              <li>
                Promote technical assessment/experimentation in the development
                process of innovative ideas/solutions to generate evidence that
                can be used to support funding opportunities or scaling up.
              </li>
              <li>
                Provide innovators with technical and business development
                support with the view of enhancing their abilities and
                commercialisation of their innovations.
              </li>
              <li>
                Identify and support local home-grown solutions from innovations
                that exist within communities.
              </li>
              <li>
                Provide the UNDP Solutions Atlas and partners’ databases for
                innovations to be showcased for matchmaking and partnerships
                with potential off takers.
              </li>
              <li>
                Contribute to the acceleration of the country’s trajectory
                towards 2030 on selected SDG indicators.
              </li>
            </ul>
            <h1>Implementation</h1>
            <p>
              NTBC and UNDP publish a call for concept notes on innovative
              products and services in the several media to accord an equal
              opportunity for everyone to participate and to maintain
              transparency.
            </p>
            <h2>Thematic Areas</h2>
            <p>
              As part of the groundwork, the implementing and collaborating
              partners work with provincial partners (Government and Private) to
              identify/formulate challenges in the respective provinces. It is
              these challenges that innovators will be responding to in the call
              for applications. For 2022, the following are the areas that the
              innovations/technologies/solutions need to address in responding
              to the challenges from the ten provinces:
            </p>
            <ol>
              <li>
                Waste Management: Efficient and sustainable
                innovations/technologies/solutions for recycling solid waste
                with focus on waste plastics, and tyres
              </li>
              <li>
                Agriculture: a) Innovations/technologies/solutions aimed at
                increasing production and productivity in agriculture. b)
                Agriculture value chains/value addition technologies for
                processing agro products (sunflower/soya beans)
              </li>
              <li>
                Mining: Sustainable artisanal/small-scale mining
                innovations/technologies/solutions, and
              </li>
              <li>
                Energy: Renewable and other alternative energy technologies to
                mitigate deforestation.
              </li>
            </ol>
            <h2>Target Group</h2>
            <p>
              The focus of the call is to support new or improved
              products/services/process that can assist to overcome societal
              challenge(s) and as such, the call will be open to any individual,
              business, or consortiums (referred hereby as ‘Applicants’) The
              applicant should develop a prototype/product aligned to address
              the identified challenge within defined timeframe. This NII
              Challenge Call will provide financial grants for market validation
              and user testing projects (with a new user group or geographic
              location) of a product, service/ or process in the respective
              province of the identified challenge. More specifically, one grant
              shall be provided for one project in each province.
            </p>
            <h2>Evaluation of the Proposals</h2>
            <p>
              Once applications have been received, a pre-screening will be
              conducted to ascertain compliance to the Challenge call criteria.
              The selection of 10 winning solutions will rest in a panel of
              judges to be agreed upon. Selected solutions will receive grant
              funding to run or improve their idea to prototype using the
              commercialisation process.
            </p>
            <h2>Eligibility Criteria</h2>
            <p>
              The NII call is open to all Zambian institutions and individuals
              as specified in section 4.2. The general eligibility is that the
              qualifying solution should be one that will primarily facilitate
              technology development, transfer, adaptation, adoption, and
              commercialization. The proposal should demonstrate evidence of
              innovativeness and competitiveness. The eligibility criteria for
              projects will be prioritized to products/ processes/ services
              that:
            </p>
            <ul>
              <li>have potential for employment and wealth creation</li>
              <li>are in value addition to available local raw materials</li>
              <li>have potential for export</li>
              <li>
                are a direct or indirect intervention to an identified societal
                challenge
              </li>
              <li>have potential to contribute to import substitution</li>
              <li>are innovative projects with high growth potential</li>
              <li>demonstrate some commercial value</li>
            </ul>
            <h2>Intellectual Property Rights</h2>
            <p>
              The ownership of the IPR shall solely belong to the innovator(s)
            </p>
            <h2>Type of Support</h2>
            <p>
              The awarded innovations will be provided with support to develop,
              refine, and pilot the innovations in collaboration with the
              challenge owners, and the relevant support institutions, i.e.,
              research and development institutions, financiers, and regulatory
              agencies
            </p>
            <h2>Terms and Conditions</h2>
            <p>
              These terms and conditions apply to the National Innovation Awards
              (NII). The applicants agree to these terms and conditions when
              they apply and if not, they are advised not to apply. These terms
              and conditions may be amended or varied at any time without prior
              notice. While the amendments will be publicized, it is the
              responsibility of the applicants to familiarize themselves with
              the changes
            </p>
            <ol>
              <li>
                The applicants must be Zambian and should attach a copy of the
                National Registration Card to the application for
              </li>
              <li>
                Applications can be submitted as individuals or by a group (all
                group members are required to provide a copy of their National
                Registration Cards)
              </li>
              <li>The applications shall be submitted online</li>
              <li>
                Successful applicants shall be required to sign a contract that
                shall oblige them to allow NTBC and the UNDP to use their
                pictures/videos, share information of their innovation with
                third party for purposes of publicity, and to develop their
                innovation for possible offtake
              </li>
              <li>
                Successful applicants shall be willing to have their innovations
                availed for testing and willingness/availability to work closely
                with the identified municipalities
              </li>
              <li>
                NTBC may refuse to support a project in an event of
                non-entitlement under the set terms and conditions or on account
                of the applicant’s breach of the terms and conditions, criminal
                activity, fraud, default, and or dishonesty
              </li>
              <li>
                NTBC reserves the right, at its sole discretion, to disqualify
                any individual on account of providing false information or
                violating the terms and conditions
              </li>
              <li>
                NTBC may use the information provided by the applicant and their
                comments for future publicity in any media without notice or any
                additional compensation
              </li>
              <li>
                NTBC does not accept responsibility for any business failure,
                loss, malfunction, damage, disappointment or fault that may be
                incurred by the applicant or the proposed product howsoever
                arising from participating in this call whether due to error,
                omission, or any other cause by the NTBC, its employees air
                others acting within the law
              </li>
              <li>
                NTBC reserves the right to make the final selection decision
              </li>
              <li>
                All applications should be received by 23:59 hours on 30th
                November 2022. All applications received after this date will
                not be considered for this call.
              </li>
            </ol> */}
          </div>
        </EuiText>
        <EuiSpacer size="l" />
        <div className={logosClass}>
          <img
            src={ntbc_logo}
            alt="NTBC Logo"
            style={{ width: "auto", height: 124, marginRight: 4 }}
          />
          {/* <img
            src={undp_logo}
            alt="UNDP Logo"
            style={{ width: "auto", height: 64, marginRight: 4 }}
          /> */}
        </div>
        <EuiSpacer size="l" />
      </div>
    </div>
  );
};
