import { EuiHealth } from "@elastic/eui";
import React from "react";

export const eduOptions = [
  {
    value: "Primary Education",
    inputDisplay: (
      <EuiHealth color="subdued" style={{ lineHeight: "inherit" }}>
        Primary Education
      </EuiHealth>
    ),
  },
  {
    value: "Secondary Education",
    inputDisplay: (
      <EuiHealth color="warning" style={{ lineHeight: "inherit" }}>
        Secondary Education
      </EuiHealth>
    ),
  },
  {
    value: "Tertiary Education",
    inputDisplay: (
      <EuiHealth color="danger" style={{ lineHeight: "inherit" }}>
        Tertiary Education
      </EuiHealth>
    ),
  },
];

export const provinceOptions = [
  {
    value: "Copperbelt",
    inputDisplay: (
      <EuiHealth color="subdued" style={{ lineHeight: "inherit" }}>
        Copperbelt
      </EuiHealth>
    ),
  },
  {
    value: "Northwestern",
    inputDisplay: (
      <EuiHealth color="warning" style={{ lineHeight: "inherit" }}>
        Northwestern
      </EuiHealth>
    ),
  },
  {
    value: "Luapula",
    inputDisplay: (
      <EuiHealth color="danger" style={{ lineHeight: "inherit" }}>
        Luapula
      </EuiHealth>
    ),
  },
  {
    value: "Lusaka",
    inputDisplay: (
      <EuiHealth color="danger" style={{ lineHeight: "inherit" }}>
        Lusaka
      </EuiHealth>
    ),
  },
  {
    value: "Western",
    inputDisplay: (
      <EuiHealth color="danger" style={{ lineHeight: "inherit" }}>
        Western
      </EuiHealth>
    ),
  },
  {
    value: "Southern",
    inputDisplay: (
      <EuiHealth color="danger" style={{ lineHeight: "inherit" }}>
        Southern
      </EuiHealth>
    ),
  },
];

export const genderOptions = [
  {
    value: "Male",
    inputDisplay: (
      <EuiHealth color="subdued" style={{ lineHeight: "inherit" }}>
        Male
      </EuiHealth>
    ),
  },
  {
    value: "Female",
    inputDisplay: (
      <EuiHealth color="warning" style={{ lineHeight: "inherit" }}>
        Female
      </EuiHealth>
    ),
  },
];
