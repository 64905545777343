import { style } from "typestyle";

export const formClass = style({
  // width: "100%",
  width: "100%",
  padding: "24px",
  maxWidth: 600,
});

export const logosClass = style({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "center",
});

export const applicationClass = style({
  width: "100%",
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  alignContent: "center",
  alignItems: "center",
});

export const titleClass = style({
  margin: "24px",
  textAlign: "center",
});
