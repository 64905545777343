import React, { useEffect, useState } from "react";
import {
  Comparators,
  EuiBasicTable,
  EuiFlexGroup,
  EuiFlexItem,
  EuiFlyout,
  EuiFlyoutBody,
  EuiFlyoutHeader,
  EuiHeader,
  EuiHeaderLink,
  EuiHeaderLinks,
  EuiHeaderLogo,
  EuiHeaderSectionItem,
  EuiHealth,
  EuiLoadingChart,
  EuiPanel,
  EuiSpacer,
  EuiStat,
  EuiSuperSelect,
  EuiText,
  EuiTitle,
} from "@elastic/eui";
import { useCollectionData } from "react-firebase-hooks/firestore";
import { db } from "./firebase/firebase";
import { format } from "date-fns";

import {
  Page,
  Text,
  Document,
  StyleSheet,
  PDFViewer,
  Font,
  Image,
} from "@react-pdf/renderer";
import { useHistory } from "react-router-dom";

Font.register({
  family: "Oswald",
  src: "https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf",
});

const styles = StyleSheet.create({
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
  },
  title: {
    fontSize: 24,
    textAlign: "center",
    fontFamily: "Oswald",
  },
  author: {
    fontSize: 12,
    textAlign: "center",
    marginBottom: 40,
  },
  subtitle: {
    fontSize: 18,
    margin: 12,
    fontFamily: "Oswald",
  },
  text: {
    margin: 12,
    fontSize: 14,
    textAlign: "justify",
    fontFamily: "Times-Roman",
  },
  image: {
    marginVertical: 15,
    marginHorizontal: 100,
  },
  header: {
    fontSize: 12,
    marginBottom: 20,
    textAlign: "center",
    color: "grey",
  },
  pageNumber: {
    position: "absolute",
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey",
  },
});

const yearOptions = [
  {
    value: "2021",
    inputDisplay: (
      <EuiHealth color="subdued" style={{ lineHeight: "inherit" }}>
        2021
      </EuiHealth>
    ),
    "data-test-subj": "option-warning",
  },
  {
    value: "2022",
    inputDisplay: (
      <EuiHealth color="warning" style={{ lineHeight: "inherit" }}>
        2022
      </EuiHealth>
    ),
    "data-test-subj": "option-minor",
  },
  {
    value: "2024",
    inputDisplay: (
      <EuiHealth color="warning" style={{ lineHeight: "inherit" }}>
        2024
      </EuiHealth>
    ),
    "data-test-subj": "option-minor",
  },
  // {
  //   value: 'critical',
  //   inputDisplay: (
  //     <EuiHealth color="danger" style={{ lineHeight: 'inherit' }}>
  //       Critical
  //     </EuiHealth>
  //   ),
  //   'data-test-subj': 'option-critical',
  // },
];

export const ApplicationSubmissions: React.FC = () => {
  const history = useHistory();

  const [applications2021, loading2021] = useCollectionData(
    db().collection("nii-applications").orderBy("timestamp", "asc")
  );

  const [applications2022, loading2022] = useCollectionData(
    db().collection("nii-applications-2022").orderBy("timestamp", "asc")
  );

  const [applications2024, loading2024] = useCollectionData(
    db().collection("nii-applications-2024").orderBy("timestamp", "asc")
  );

  const [year, setYear] = useState<string>("2021");
  const [applications, setApplications] = useState<any[]>([]);

  useEffect(() => {
    if (year === "2021") {
      setApplications(applications2021 as any[]);
    } else if (year === "2022") {
      setApplications(applications2022 as any[]);
    } else {
      setApplications(applications2024 as any[]);
    }
  }, [applications2021, applications2022, applications2024, year]);

  // useEffect(() => {
  //   console.log(applications);
  // }, [loading, applications]);

  const [currentApplication, setCurrentApplication] = useState<any>(null);
  const [isFlyoutVisible, setFlyoutVisible] = useState<boolean>(false);
  const [isLoadingPDF, setLoadingPDF] = useState<boolean>(true);

  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(6);

  const some = (
    pageIndex: number,
    pageSize: number,
    sortField?: any,
    sortDirection?: any
  ) => {
    let items: any = [];

    if (sortField) {
      items = (applications || [])
        .slice(0)
        .sort(
          Comparators.property(sortField, Comparators.default(sortDirection))
        );
    } else {
      items = applications;
    }

    let pageOfItems;

    if (!pageIndex && !pageSize) {
      pageOfItems = items;
    } else {
      const startIndex = pageIndex * pageSize;
      pageOfItems = items?.slice(
        startIndex,
        Math.min(startIndex + pageSize, items.length)
      );
    }

    return {
      pageOfItems,
      totalItemCount: items?.length,
    };
  };

  const { pageOfItems, totalItemCount } = some(pageIndex, pageSize);

  const pagination = {
    pageIndex: pageIndex,
    pageSize: pageSize,
    totalItemCount: totalItemCount || 0,
    pageSizeOptions: [5, 8, 16, 20],
  };

  const onTableChange = ({ page = {} as any }) => {
    const { index: pageIndex, size: pageSize } = page;

    setPageIndex(pageIndex);
    setPageSize(pageSize);
  };

  const columns = [
    {
      field: "innovatorName",
      name: "Innovator Name",
      sortable: true,
    },
    {
      field: "contact",
      name: "Contact Number",
      sortable: true,
    },
    {
      field: "email",
      name: "Email",
      sortable: true,
    },
    {
      field: "province",
      name: "Province",
      sortable: true,
    },
    {
      field: "title",
      name: "Innovation Title",
    },
    {
      field: "timestamp",
      name: "Date Submitted",
      render: (date: any) => {
        return `${format(date.toDate(), "EEE do MMM yyyy, h:mm:ss")}`;
      },
    },
  ];

  const getRowProps = (item: any) => {
    const { id } = item;
    return {
      "data-test-subj": `row-${id}`,
      className: "customRowClass",
      onClick: () => {
        setCurrentApplication(item);
        setFlyoutVisible(true);
        setLoadingPDF(true);
      },
    };
  };

  const logout = () => {
    history.push("/admin/login");
  };

  return (
    <div>
      <EuiHeader>
        <EuiHeaderSectionItem border="right">
          <EuiHeaderLogo>National Innovation Initiative</EuiHeaderLogo>
        </EuiHeaderSectionItem>

        <EuiHeaderSectionItem>
          <EuiHeaderLinks>
            <EuiHeaderLink iconType="exit" onClick={() => logout()}>
              Logout
            </EuiHeaderLink>
          </EuiHeaderLinks>
        </EuiHeaderSectionItem>
      </EuiHeader>
      <div style={{ margin: 24, height: "100vh" }}>
        <EuiSuperSelect
          options={yearOptions}
          valueOfSelected={year}
          defaultValue="2021"
          onChange={(value) => setYear(value)}
        />
        <EuiText textAlign="center">
          <h2>National Innovation Initiative ({year})</h2>
          <h3 style={{ marginTop: 0 }}>Application Submissions</h3>
        </EuiText>
        <EuiSpacer size="l" />
        <EuiFlexGroup>
          <EuiFlexItem>
            <EuiStat
              title={applications?.length || "..."}
              description="Total Applications"
              titleColor="danger"
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiStat
              title={
                applications?.filter((x: any) => x.province === "Lusaka")
                  .length || "..."
              }
              description="Lusaka"
              titleColor="primary"
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiStat
              title={
                applications?.filter((x: any) => x.province === "Luapula")
                  .length || "..."
              }
              description="Luapula"
              titleColor="primary"
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiStat
              title={
                applications?.filter((x: any) => x.province === "Copperbelt")
                  .length || "..."
              }
              description="Copperbelt"
              titleColor="primary"
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiStat
              title={
                applications?.filter((x: any) => x.province === "Western")
                  .length || "..."
              }
              description="Western"
              titleColor="primary"
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiStat
              title={
                applications?.filter((x: any) => x.province === "Northwestern")
                  .length || "..."
              }
              description="North Western"
              titleColor="primary"
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiStat
              title={
                applications?.filter((x: any) => x.province === "Southern")
                  .length || "..."
              }
              description="Southern"
              titleColor="primary"
            />
          </EuiFlexItem>
        </EuiFlexGroup>
        <EuiSpacer size="l" />
        <EuiPanel>
          <EuiBasicTable
            items={(pageOfItems as any[]) ?? []}
            loading={loading2021 || loading2022 || loading2024}
            rowHeader="firstName"
            columns={columns}
            pagination={pagination}
            rowProps={getRowProps}
            onChange={onTableChange}
          />
        </EuiPanel>

        {isFlyoutVisible && (
          <EuiFlyout
            ownFocus
            onClose={() => setFlyoutVisible(false)}
            aria-labelledby="flyoutTitle"
          >
            <EuiFlyoutHeader hasBorder>
              <EuiTitle size="m">
                <h2 id="flyoutTitle">Application Details</h2>
              </EuiTitle>
            </EuiFlyoutHeader>
            <EuiFlyoutBody>
              {isLoadingPDF && (
                <EuiText textAlign="center">
                  <EuiLoadingChart size="xl" mono />
                  <h3>Loading document...</h3>
                </EuiText>
              )}
              <div>
                {currentApplication && (
                  <PDFViewer
                    style={{
                      position: "absolute",
                      border: 0,
                      height: "100%",
                      width: "95%",
                    }}
                  >
                    <Document onRender={() => setLoadingPDF(false)}>
                      <Page style={styles.body}>
                        <Text style={styles.title}>
                          {currentApplication?.title}
                        </Text>
                        <Text style={styles.author}>
                          {currentApplication?.innovatorName} -{" "}
                          {currentApplication.province} Province
                        </Text>
                        {currentApplication?.fileUrl && (
                          <Image
                            style={styles.image}
                            src={currentApplication.fileUrl}
                          />
                        )}
                        <Text style={styles.subtitle}>Personal Details</Text>
                        <Text style={styles.text}>
                          Full name: {currentApplication.innovatorName}
                        </Text>
                        <Text style={styles.text}>
                          Email: {currentApplication.email}
                        </Text>
                        <Text style={styles.text}>
                          Contact Number: {currentApplication.contact}
                        </Text>
                        <Text style={styles.text}>
                          Alternative Contact: {currentApplication.altContact}
                        </Text>
                        <Text style={styles.text}>
                          NRC Number: {currentApplication.nrcNumber}
                        </Text>
                        <Text style={styles.text}>
                          Date of Birth:{" "}
                          {format(
                            currentApplication.dob.toDate(),
                            "do MMM yyyy"
                          )}
                        </Text>
                        <Text style={styles.text}>
                          Gender: {currentApplication.gender}
                        </Text>
                        <Text style={styles.text}>
                          Education Level: {currentApplication.eduLevel}
                        </Text>
                        <Text style={styles.text}>
                          Disability: {currentApplication.disability}
                        </Text>
                        <Text style={styles.subtitle}>
                          Brief Description of Innovation
                        </Text>
                        <Text style={styles.text}>
                          Has Prototype:{" "}
                          {currentApplication.hasPrototype ? "[Yes]" : "[No]"}{" "}
                          Is Publication Permission Given:{" "}
                          {currentApplication.isPermissionGiven
                            ? "[Yes]"
                            : "[No]"}
                        </Text>
                        <Text style={styles.text}>
                          {currentApplication.description}
                        </Text>
                        <Text style={styles.subtitle}>
                          Commercial Viability of Innovation
                        </Text>
                        <Text style={styles.text}>
                          {currentApplication.viability}
                        </Text>
                        <Text style={styles.subtitle}>
                          Technical Feasibility
                        </Text>
                        <Text style={styles.text}>
                          {currentApplication.feasibility}
                        </Text>
                        <Text style={styles.subtitle}>
                          Anticipated Social Impact
                        </Text>
                        <Text style={styles.text}>
                          {currentApplication.socialImpact}
                        </Text>
                        <Text
                          style={styles.pageNumber}
                          render={({ pageNumber, totalPages }) =>
                            `${pageNumber} / ${totalPages}`
                          }
                          fixed
                        />
                        <Text style={styles.subtitle}>
                          Sustainable Development Goals
                        </Text>
                        {currentApplication.sdgs.map((x: any) => {
                          return (
                            <Text key={x.label} style={styles.text}>
                              {x.label}
                            </Text>
                          );
                        })}
                      </Page>
                    </Document>
                  </PDFViewer>
                )}
              </div>
            </EuiFlyoutBody>
          </EuiFlyout>
        )}
      </div>
    </div>
  );
};
