import firebase from "firebase";

const config = {
  apiKey: "AIzaSyCJo_yMI6uO7LeLax7vaffJyKgTUSkrh9U",
  authDomain: "ntbc-conduit.firebaseapp.com",
  databaseURL: "https://ntbc-conduit.firebaseio.com",
  projectId: "ntbc-conduit",
  storageBucket: "ntbc-conduit.appspot.com",
  messagingSenderId: "443703892848",
  appId: "1:443703892848:web:b062cef193ec885b3c4db5",
  measurementId: "G-R1EBPQ4MPW",
};

firebase.initializeApp(config);
firebase.analytics();

export const db = firebase.firestore;
export const storage = firebase.storage;
